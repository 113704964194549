/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import './index.scss';

import { createRoot } from 'react-dom/client';
import invariant from 'tiny-invariant';

import LazyAppV1 from 'ui-gateway/src/v1/LazyAppV1';
import LazyAppV2 from 'ui-gateway/src/v2/LazyAppV2';

const rootElement = document.getElementById('recApp');
invariant(rootElement, 'Failed to find the root element');

const root = createRoot(rootElement);

root.render(process.env.GATEWAY_VERSION === 'v2' ? <LazyAppV2 /> : <LazyAppV1 />);
